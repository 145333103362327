<template>
  <div class="pt-6 d-flex align-center flex-column white h-full">
    <div>
      <h1 class="mb-4 display-1 black--text font-weight-black text-center">
        <span v-if="productType === productTypeEnum.BEGINNER">
          {{ $t('payment.title-beginner-package') }}
        </span>
        <span v-else>{{ $t('payment.title') }}</span>
      </h1>
    </div>
    <v-row class="text-center">
      <v-col>
        <div class="benefits d-flex text-caption mt-2 mb-md-6 flex-row flex-wrap justify-md-center">
          <p class="px-0 mx-md-8 mb-2 nowrap">
            <v-icon
              class="ml-0 px-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('payment.benefits.option-one') }}
          </p>
          <p class="px-0 mr-md-8 mx-md-8 mb-2 nowrap">
            <v-icon
              class="ml-0 px-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            <span v-if="isYearly"> {{ $t('payment.benefits.option-two') }}</span>
            <span v-else> {{ $t('payment.benefits.option-three') }}</span>
          </p>
          <p class="px-0 mb-2 mx-md-8 nowrap">
            <v-icon
              class="ml-0 px-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('payment.benefits.option-four') }}
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="5"
        offset-lg="1"
        order="2"
        order-lg="1"
      >
        <p class="font-weight-bold pa-4">
          {{ $t('payment.billing-data.title') }}
        </p>
        <Subscribe
          ref="subscribe"
          :coupon-code="couponCode"
          :country-code="countryCode"
          :product-type="productType"
          :readable-ids="readableIds"
          :email="$auth.user.email"
          is-form-only
          hide-submit
          show-country
          @completed="completed"
          @previewOrder="previewOrder = $event"
          @state="state = $event"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
        order="1"
        order-lg="2"
      >
        <v-card class="pa-4 pa-md-8 pt-4 mx-md-4 shadow">
          <div>
            <p class="font-weight-bold px-0">
              {{ $t('payment.billing-info.title') }}
            </p>
            <v-radio-group
              v-model="isYearly"
              row
            >
              <RadioButton
                class="flex-grow-1"
                :value="true"
                :group-value="isYearly"
              >
                <div class="font-weight-bold pb-2">
                  {{ $t('payment.billing-info.charge.label-two') }}
                </div>
                <div>{{ $t('payment.billing-info.description', {percent: 20}) }}</div>
              </RadioButton>
              <RadioButton
                class="flex-grow-1"
                :value="false"
                :group-value="isYearly"
              >
                <div class="font-weight-bold pb-2 pb-6">
                  {{ $t('payment.billing-info.charge.label-one') }}
                </div>
              </RadioButton>
            </v-radio-group>
          </div>
          <p class="font-weight-bold px-0 mt-4">
            {{ $t('payment.coupon.title') }}
          </p>
          <Coupon
            :coupon-code="couponCode"
            :coupon="{isCouponValid: previewOrder?.isCouponValid, ...previewOrder?.coupon}"
            @apply-coupon="setCouponCode"
          />
          <PreviewOrder
            v-if="previewOrder"
            class="col-12 mt-0 mb-5 pa-0"
            :preview-order="previewOrder"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-0 ma-md-4 d-flex btns-wrapper">
      <v-btn
        outlined
        :color="$vuetify.theme.themes.light.markero.blue"
        class="mr-sm-6 mx-sm-8 px-sm-16 py-6 back-btn"
        :disabled="state === 'customerForm.submitting'"
        @click="goBack"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-btn
        elevation="20"
        :color="$vuetify.theme.themes.light.markero.blue"
        class="ml-sm-6 mx-sm-8 px-sm-16 py-6 white--text proceed-btn"
        :loading="state === 'customerForm.submitting'"
        @click="submit"
      >
        {{ $t('labels.proceed') }}
      </v-btn>
    </v-row>

    <v-row class="ma-5 ">
      <v-col
        class="px-0"
        md="8"
        offset-md="2"
      >
        <v-card
          elevation="0"
          class="card-wrapper pa-4 d-flex text-center"
        >
          <div>
            <div class="d-flex justify-center">
              <v-img
                alt="Company Logo"
                class="mx-1 inline-image "
                :src="require('@/../public/img/logo-markero.png')"
                width="0"
                height="40"
                contain
              />
              <span class="part-of">{{ $t('payment.disclaimer-one') }} </span>
              <v-img
                class="mx-1 inline-image "
                :src="require('@/../public/img/logo-bottimmo.png')"
                width="0"
                height="40"
                contain
              />
            </div>
            {{ $t('payment.disclaimer-two') }} Billwerk+.
            {{ $t('payment.disclaimer-three') }}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-5">
      <div class="d-flex flex-wrap justify-center">
        <IubendaDocumentLink
          type="terms"
          class="mx-1 mx-sm-2"
          @click.stop
        />
        <IubendaDocumentLink
          class="mx-1 mx-sm-2"
          type="privacyPolicy"
          @click.stop
        />
        <IubendaConsentLink class="mx-1 mx-sm-2" />
        <a
          :href="imprintUrl"
          target="_blank"
          class="mx-1 mx-sm-2"
          rel="noopener noreferrer"
        >{{ $t('navigation.labels.imprint') }}</a>
      </div>
    </v-row>
    <v-spacer class="h-full" />
  </div>
</template>

<script>
import Subscribe from '@/modules/billwerk/subscribe/index.vue'
import RadioButton from '@/components/RadioButton.vue'
import featureMixin from '@/mixins/feature'
import { ProductType } from '@/modules/productPackages/enums/ProductType'
import PreviewOrder from '../billwerk/subscribe/PreviewOrder.vue'
import Coupon from '../billwerk/subscribe/Coupon.vue'
import trackingEvents from '@/lib/trackingEvents'
import { Routes } from '@/components/product-finder/routes'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: { IubendaDocumentLink, IubendaConsentLink, Subscribe, RadioButton, PreviewOrder, Coupon },
  mixins: [featureMixin, brandingMixin],
  data () {
    this.productPackageQuery = JSON.parse(sessionStorage.getItem('productPackageQuery'))
    return {
      previewOrder: null,
      productTypeEnum: ProductType,
      readableIds: this.productPackageQuery?.readableIds,
      productType: this.productPackageQuery?.productType,
      isYearly: this.productPackageQuery?.isYearly,
      monthlyPrice: this.productPackageQuery?.monthlyPrice,
      yearlyPrice: this.productPackageQuery?.yearlyPrice,
      countryCode: this.productPackageQuery?.countryCode,
      currencySymbol: this.productPackageQuery?.currencySymbol,
      hasPaymentProvider: false,
      dialog: true,
      couponCode: this.productPackageQuery?.couponCode || '',
      state: null,
      isBillingInProgress: false
    }
  },
  computed: {
    hasActivePackage () {
      return !!this.$auth?.user?.productType
    }
  },
  provide () {
    return {
      billingOnboardingDetailsData: {}
    }
  },
  watch: {
    isYearly (newValue) {
      const timePeriod = newValue ? 'yearly' : 'monthly'
      this.updateReadableId(timePeriod)
      this.setToSessionStorage()
    }
  },
  mounted () {
    this.$tracking.event(trackingEvents.ADD_PAYMENT_INFO, this.$tracking.trackingEvents.CLICKED)
  },
  methods: {
    setCouponCode (code) {
      this.couponCode = code
      this.setToSessionStorage()
    },
    submit () {
      this.$tracking.event('Payment', this.$tracking.trackingEvents.CLICKED, 'Proceed')
      this.$refs.subscribe.submitCustomerForm()
    },
    completed () {
      this.isBillingInProgress = false
      sessionStorage.removeItem('productPackageQuery')
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.LOADED, 'Initial App Load')

      if (this.hasActivePackage) {
        const productType = this.productPackageQuery?.productType
        const isProductTypeBeginner = productType === ProductType.BEGINNER
        const event = isProductTypeBeginner ? this.$tracking.trackingEvents.DOWNGRADED : this.$tracking.trackingEvents.UPGRADED
        this.$tracking.event('Plan', event, productType)
        return this.$router.push({ name: 'dashboard' })
      }

      this.$router.push(`/signup-markero/${Routes.WEBSITE}`)
    },
    updateReadableId (timePeriod) {
      this.readableIds = this.readableIds.map(id => this.replaceLastSegment(id, timePeriod))
    },
    replaceLastSegment (id, newSegment) {
      const segments = id.split('-')
      if (segments[segments.length - 1] === 'monthly' || segments[segments.length - 1] === 'yearly') {
        segments[segments.length - 1] = newSegment
      }
      return segments.join('-')
    },
    setToSessionStorage () {
      sessionStorage.setItem('productPackageQuery', JSON.stringify(
        {
          productType: this.productType,
          isYearly: this.isYearly,
          countryCode: this.countryCode,
          currencySymbol: this.currencySymbol,
          companyId: this.companyId,
          monthlyPrice: this.monthlyPrice,
          yearlyPrice: this.yearlyPrice,
          readableIds: this.readableIds,
          couponCode: this.couponCode
        }
      ))
    },
    goBack () {
      this.$tracking.event('Payment', this.$tracking.trackingEvents.CLICKED, 'Back')
      this.$router.push({
        name: 'product-packages',
        query: {
          countryCode: this.$route.query?.countryCode,
          readableId: this.$route.query?.readableId,
          currencySymbol: this.$route.query?.currencySymbol,
          isYearly: this.$route.query?.isYearly,
          companyId: this.$route.query?.companyId
        }
      })
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .benefits {
    justify-content: space-evenly;
  }
}

.card-wrapper {
  border-radius: 10px !important;
  background: #FFFFFF !important;
  box-shadow: 10px 10px 40px 0 rgba(35, 28, 88, 0.10) !important;
}

.nowrap {
  white-space: nowrap;
}
.inline-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 115px;
}

.part-of {
  font-size: 14px;
  color: rgb(128, 128, 128);
  align-self: center;
  margin: 0 5px;
}

::v-deep fieldset   {
  background-color: white;
}

.shadow {
  box-shadow: 20px 20px 180px 20px rgba(48, 34, 147, 0.10) !important;
}
</style>
<style>
.v-input--radio-group__input {
  gap: 1rem;
}
@media (max-width: 600px) {
  .proceed-btn, .back-btn {
    min-width: 120px !important;

  }
  .btns-wrapper {
    gap: 2rem;
  }
}
</style>
